import { Component, NgZone, OnInit } from '@angular/core';
import { LocalSystemService } from '../local-system.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-general-service',
  templateUrl: './general-service.component.html',
  styleUrls: ['./general-service.component.css']
})
export class GeneralServiceComponent implements OnInit {
  protocols:any = [];
  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];
  modal:any = { open:false, cntx:"", obj:{} }

  search_by = 'client_name';
  search:any = '';
  searched:any = false;

  constructor(public ngZone:NgZone, public system:LocalSystemService, public http:HttpClient) { }

  ngOnInit(): void {
    this.getProtocols();
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj } }
  closeModal() { this.modal.open = false; }

  getProtocols() {
    this.openModal('loading-fuji-services', {});
    let url = "api/service.php?action=get_fuji_services&page=" + this.currentPage + "&search_by=" + this.search_by;
    // if(this.brand != 'all') { url += "&by_brand=" + this.brand; }
    // if(this.timeline != '0') { url += "&by_date=" + this.timeline; }
    // if(this.serial != '') { url += "&serial=" + this.serial; }
    this.http.get(url).subscribe((data) => {
      if(data['logged']) {

        // if(this.search_by == 'serial' && this.serial != '') { this.searched = true; }
        this.protocols = data['protocols'];
        this.total_pages = data['total_pages'];
        this.total_pages_array = [];
        for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
        this.ngZone.run(()=> {});
        setTimeout(()=>{
          this.closeModal();
        }, 500);
      } else {
        this.system.Logout();
      }
    });
  }

  searchProtocolDrones() {
    this.currentPage = 1;
    this.searched = true;
    this.openModal('loading-drone-services', {});
    // this.http.get("api/service.php?action=search_drone_service&page=" + this.currentPage + "&search_type=" + this.search_by + "&search=" + this.search).subscribe((data) => {
    //   this.protocols = data['protocols'];
    //   this.total_pages = data['total_pages'];
    //   this.total_pages_array = [];
    //   for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    //   this.closeModal();
    //   localStorage.setItem('drones_search_obj', JSON.stringify({ 'page':this.currentPage, 'searched':this.searched, 'search_type':this.search_by, 'search':this.search }));
    // });
  }
  switchPageSearch() {
    this.openModal('loading-drone-services', {})
    // this.http.get("api/service.php?action=search_drone_service&page=" + this.currentPage + "&search_type=" + this.search_by + "&search=" + this.search).subscribe((data) => {
    //   this.protocols = data['protocols'];
    //   this.total_pages = data['total_pages'];
    //   this.total_pages_array = [];
    //   for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    //   this.closeModal();
    //   localStorage.setItem('drones_search_obj', JSON.stringify({ 'page':this.currentPage, 'searched':this.searched, 'search_type':this.search_by, 'search':this.search }));
    // });
  }
  clearSearched() { 
    this.searched = false; 
    this.search_by = "client_name";
    this.search = "";
    this.currentPage = 1;
    localStorage.setItem('drones_search_obj', JSON.stringify({ 'page':this.currentPage, 'searched':this.searched, 'search_type':this.search_by, 'search':this.search }));
    this.getProtocols();
  }


  switchPage(page) {
    this.currentPage = page;
    if(!this.searched) { this.getProtocols(); }
    else { this.switchPageSearch(); }
  }

 
  promptDelete(protocol_id) { this.openModal('remove-protocol', { 'protocol_id':protocol_id }); }
  deleteProtocol(protocol_id) {
    this.http.get("api/service.php?action=remove_fuji_service&protocol_id=" + protocol_id).subscribe((data) => {
      this.closeModal();
      this.getProtocols();
    });
  }


  printFujiProtocol(protocol) {
    window.location.href = 'http://warranty.tempex.bg/print_fuji_protocol.php?protocol_id=' + protocol.service_num;
  }



}
