import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-edit-fuji-service',
  templateUrl: './edit-fuji-service.component.html',
  styleUrls: ['./edit-fuji-service.component.css']
})
export class EditFujiServiceComponent implements OnInit {
  tab:any = "general";
  editProtocol:any = { 'service_num':10000, 'date_of_sale':new Date(), 'date_of_installation':new Date(), "seller_phone":"", "warranty_num":"",
    model_indoor:"", serial_indoor:"", model_outdoor:"", serial_outdoor:"", dealer_notes:[{ 'part':'', 'description':'' }], outcome:""
  }

  modal:any = { "open":false, 'cntx':'', obj:{} };

  sale_date_picker:any = false;
  current_sale_date:any;
  current_sale_month_dates:any = [];

  date_installation_picker:any = false;
  current_installation_date:any;
  current_installation_month_dates:any = [];
  timeoutPicker:any;

  service_num = 0;

  constructor(public http:HttpClient, public router:Router, public system:LocalSystemService, public activatedRoute:ActivatedRoute) {
    this.FetchSellerOffice();
    this.service_num = this.activatedRoute.snapshot.params.uid;
    this.FetchService();
  }

  FetchService() {
    this.http.get("api/service.php?action=fetch_fuji_service&protocol_id=" + this.service_num).subscribe((response) => {
      this.editProtocol['service_num'] = this.service_num;
      this.editProtocol['seller_phone'] = response['protocol']['seller_phone'];
      this.editProtocol['warranty_num'] = response['protocol']['warranty_num'];
      this.editProtocol['model_indoor'] = response['protocol']['model_indoor'];
      this.editProtocol['model_outdoor'] = response['protocol']['model_outdoor'];
      this.editProtocol['serial_indoor'] = response['protocol']['serial_indoor'];
      this.editProtocol['serial_outdoor'] = response['protocol']['serial_outdoor'];
      this.editProtocol['outcome'] = response['protocol']['outcome'];
      this.editProtocol['dealer_notes'] = JSON.parse(response['protocol']['defected_part']);
      this.editProtocol['date_of_sale'] = new Date(response['protocol']['date_of_sale']);
      this.editProtocol['date_of_installation'] = new Date(response['protocol']['date_of_installation']);
      this.editProtocol['date_of_service'] = response['protocol']['date_of_service'];
      this.editProtocol['date_of_return'] = response['protocol']['date_of_return'];
      this.editProtocol['client_name'] = response['protocol']['client_name'];
      this.editProtocol['client_phone'] = response['protocol']['client_phone'];
      this.editProtocol['client_address'] = response['protocol']['client_address'];
      this.editProtocol['status'] = response['protocol']['status'];

      this.editProtocol.formatted_date_of_sale = this.returnDateFormated(this.editProtocol.date_of_sale);
      this.editProtocol.formatted_date_of_installation = this.returnDateFormated(this.editProtocol.date_of_installation);
    });
  }

  ngOnInit(): void {

  }

  EditProtocol() {
    this.openModal('adding-protocol', {});
    let post_data = { 'service_num':this.editProtocol.service_num, 'seller_phone':this.editProtocol.seller_phone, 'client_name':this.editProtocol.client_name , 
    'date_of_sale':this.returnDateFormatedForStorage(this.editProtocol.date_of_sale), 'date_of_installation':this.returnDateFormatedForStorage(this.editProtocol.date_of_installation), 
    'client_phone':this.editProtocol.client_phone, 'client_address':this.editProtocol.client_address, 'warranty_num':this.editProtocol.warranty_num,
    'model_indoor':this.editProtocol.model_indoor, 'model_outdoor':this.editProtocol.model_outdoor, 'serial_indoor':this.editProtocol.serial_indoor, 
    'serial_outdoor':this.editProtocol.serial_outdoor, 'defected_part':JSON.stringify(this.editProtocol.dealer_notes), 'outcome':this.editProtocol.outcome, 'status':this.editProtocol.status,
    'date_of_service':this.editProtocol.date_of_service, 'date_of_return':this.editProtocol.date_of_return};

    this.http.post("api/service.php?action=edit_fuji_service", post_data).subscribe((data) => {
      setTimeout(()=>{ 
        this.closeModal();
        this.router.navigate(['service']);
      }, 500);
    });
  }

  AddNewDefectedPart() {
    this.editProtocol.dealer_notes.push({ 'part':'', 'description':'' })
  }

  FetchSellerOffice() {
    this.http.get("api/service.php?action=get_offices&page=1").subscribe((data) => {
      if(data['offices'] != undefined) {
        if(data['offices'].length > 0) {
          this.editProtocol.seller_phone = data['offices'][0]['phone'];
        }
      }
    });
  }

  FetchWarrantyCardInfo() { 
    this.http.get("api/service.php?action=fetch_warranty_card&warranty_num=" + this.editProtocol.warranty_num).subscribe((data) => {
      if(data['warranty'].brand != undefined) {
        if(data['warranty'].brand.indexOf("Fuji Electric") > -1) {
          this.editProtocol.client_name = data['warranty']['client_name'];
          this.editProtocol.client_address = data['warranty']['client_address'];
          this.editProtocol.client_phone = data['warranty']['client_phone'];
        } else {
          alert("Гар.карта която се опитвате да изтеглите няма Fuji electric продукти!")
        }
      } else {
        alert("Няма гар.карта с този номер!");
      }
    })
  }

  GetMaxProtocol() {
    let url = "api/service.php?action=get_max_fuji_service";
    this.http.get(url).subscribe((data) => {
      if(data['logged']) {
        if(data['max_fuji'] != undefined) {
          if(data['max_fuji']['service_num'] !== undefined && data['max_fuji']['service_num'] != null) {
            this.editProtocol.service_num = parseInt(data['max_fuji']['service_num']) + 1;
          }
        }
      }
    });
  }

  switchTab(tab) { this.tab = tab; }

  // DATE PICKER FUNCTIONS
  selectDate(dt, type){
    if(type == 'date_of_sale') {
      this.editProtocol.date_of_sale.setYear(this.current_sale_date.getFullYear());
      this.editProtocol.date_of_sale.setDate(dt.date);
      this.editProtocol.date_of_sale.setMonth(dt.month);
      this.sale_date_picker = false;
      this.editProtocol.formatted_date_of_sale = this.returnDateFormated(this.editProtocol.date_of_sale);
    } else if(type == 'date_of_installation') {
      this.editProtocol.date_of_installation.setYear(this.current_installation_date.getFullYear());
      this.editProtocol.date_of_installation.setDate(dt.date);
      this.editProtocol.date_of_installation.setMonth(dt.month);
      this.date_installation_picker = false;
      this.editProtocol.formatted_date_of_installation = this.returnDateFormated(this.editProtocol.date_of_installation);
    } else {
      type.date.setYear(type.current_date.getFullYear());
      type.date.setDate(dt.date);
      type.date.setMonth(dt.month);
      type.picker = false;
    }
  }

  nextMonth() { this.current_sale_date.setMonth(this.current_sale_date.getMonth() + 1); this.Formulate(this.current_sale_date); }
  prevMonth() { this.current_sale_date.setMonth(this.current_sale_date.getMonth() - 1); this.Formulate(this.current_sale_date); }

  ChangeDate(type) {
    if(type == 'date_of_sale') {
      let split_date = this.editProtocol.formatted_date_of_sale.split(".");
      this.editProtocol.date_of_sale.setDate(parseInt(split_date[0]));
      this.editProtocol.date_of_sale.setMonth(parseInt(split_date[1]) - 1);
      this.editProtocol.date_of_sale.setFullYear(parseInt(split_date[2]));
    } else if(type == 'date_of_installation') {
      let split_date = this.editProtocol.formatted_date_of_sale.split(".");
      this.editProtocol.date_of_installation.setDate(parseInt(split_date[0]));
      this.editProtocol.date_of_installation.setMonth(parseInt(split_date[1]) - 1);
      this.editProtocol.date_of_installation.setFullYear(parseInt(split_date[2]));
    }
  }

  Formulate(dt) {
    this.current_sale_month_dates = [];

    var firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let predate_num = 0;
    // GENERATE PREDATE

    for(let i = 0; i < 42 - lastDay.getDate(); i++) {
        let day_idx = i;
        let first_day = firstDay.getDay();
        if(first_day == 0) { first_day = 7; }
        if(day_idx < first_day - 1) {
            let negative_day = day_idx * -1;
            let date_reformed = new Date(dt.getFullYear(), dt.getMonth(), negative_day);
            this.current_sale_month_dates.unshift({ "date": date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false})
            predate_num += 1;
        }
    }
    for(let i = 0; i < lastDay.getDate(); i++) {
        let day_id = i + 1;
        this.current_sale_month_dates.push({ "date":day_id, "month":dt.getMonth(), "active":true });
    }
    for(let i = 0; i < 42 - lastDay.getDate() - predate_num; i++) {
        let date_reformed = new Date(dt.getFullYear(), dt.getMonth() + 2, i + 1);
        this.current_sale_month_dates.push({ "date":date_reformed.getDate(), "month":date_reformed.getMonth(), "active":false });
    }
  }

  openPicker(type) {
    this.timeoutPicker = setTimeout(()=>{      
      if(type == 'date_of_sale') {
        let dt = new Date();
        this.sale_date_picker = true; 
        if(this.editProtocol.date_of_sale != '') {
          this.current_sale_date = new Date(this.editProtocol.date_of_sale);
        } else {
          this.editProtocol.date_of_sale = dt;
          this.current_sale_date = new Date(dt);
        }
        this.Formulate(this.current_sale_date);
      } else {
        this.date_installation_picker = true; 
        this.current_installation_date = new Date(this.editProtocol.date_of_installation);
        this.Formulate(this.current_installation_date);
      }
    }, 50);
  }

  // MODEL
  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj } }
  closeModal() { this.modal.open = false; }

  // UTILITY
  returnDateFormatedForStorage(dt) {
    return dt.getFullYear() + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + this.returnPadZero(dt.getDate(), 2) +  " / " +  this.returnPadZero(dt.getHours(), 2) + ":" + this.returnPadZero(dt.getMinutes(), 2) + ":" + this.returnPadZero(dt.getSeconds(), 2);
  }

  returnDateFormated(dt){
    if(dt != '') {
      return this.returnPadZero(dt.getDate(), 2) + "." + this.returnPadZero(dt.getMonth()+ 1, 2) + "." + dt.getFullYear();
    } else {
      return '';
    }
  }

  returnPadZero(number, length) {

    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }

    return my_string;
  }


  returnYear(dt) { 
    if(dt !== undefined) {
      return dt.getFullYear(); 
    } else {
      return '';
    }
  }
  returnMonth(dt) {
    if(dt !== undefined) {
      return dt.getMonth() + 1; 
    } else {
      return '';
    }
  }
  returnMonthName(month) {
    if(month == 1) { return "Януари"; }
    else if(month == 2) { return "Февруари"; }
    else if(month == 3) { return "Март"; }
    else if(month == 4) { return "Април"; }
    else if(month == 5) { return "Май"; }
    else if(month == 6) { return "Юни"; }
    else if(month == 7) { return "Юли"; }
    else if(month == 8) { return "Август"; }
    else if(month == 9) { return "Септември"; }
    else if(month == 10) { return "Октомври"; }
    else if(month == 11) { return "Ноември"; }
    else if(month == 12) { return "Декември"; }
    else { return ""; }
  }
  returnDate(dt) { return dt.getDate();  }

}
