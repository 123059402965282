import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddDealerComponent } from './add-dealer/add-dealer.component';
import { AddDeliveryComponent } from './add-delivery/add-delivery.component';
import { AddSaleComponent } from './add-sale/add-sale.component';
import { AddStorehouseComponent } from './add-storehouse/add-storehouse.component';
import { AddUserComponent } from './add-user/add-user.component';
import { AddWarrantyComponent } from './add-warranty/add-warranty.component';
import { BrandsComponent } from './brands/brands.component';
import { DealersComponent } from './dealers/dealers.component';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { SalesComponent } from './sales/sales.component';
import { SearchProductComponent } from './search-product/search-product.component';
import { StorehousesComponent } from './storehouses/storehouses.component';
import { UsersComponent } from './users/users.component';
import { WarrantiesComponent } from './warranties/warranties.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { EditBrandComponent } from './edit-brand/edit-brand.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditDeliveryComponent } from './edit-delivery/edit-delivery.component';
import { HelpComponent } from './help/help.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { EditDocumentComponent } from './edit-document/edit-document.component';
import { EditWarrantyComponent } from './edit-warranty/edit-warranty.component';
import { ProfileComponent } from './profile/profile.component';
import { EditSaleComponent } from './edit-sale/edit-sale.component';
import { OtherComponent } from './other/other.component';
import { ErrorCodesComponent } from './error-codes/error-codes.component';
import { DeliveriesDealersComponent } from './deliveries-dealers/deliveries-dealers.component';
import { DroneServiceComponent } from './drone-service/drone-service.component';
import { AddDroneServiceComponent } from './add-drone-service/add-drone-service.component';
import { EditDroneServiceComponent } from './edit-drone-service/edit-drone-service.component';
import { GeneralServiceComponent } from './general-service/general-service.component';
import { AddFujiServiceComponent } from './add-fuji-service/add-fuji-service.component';
import { EditFujiServiceComponent } from './edit-fuji-service/edit-fuji-service.component';

const routes: Routes = [
  { path: '', redirectTo: '/deliveries', pathMatch: 'full' },
  { path: 'deliveries', component: DeliveriesComponent },
  { path: 'deliveries-dealers', component: DeliveriesDealersComponent },
  { path: 'sales', component: SalesComponent },
  { path: 'warranties', component: WarrantiesComponent },
  { path: 'error-codes', component: ErrorCodesComponent },
  { path: 'documentation', component: DocumentationComponent },
  { path: 'users', component: UsersComponent },
  { path: 'add-delivery', component:AddDeliveryComponent },
  { path: 'add-user', component:AddUserComponent }, 
  { path: 'add-dealer', component:AddDealerComponent },
  { path: 'add-storehouse', component:AddStorehouseComponent },
  { path: 'add-warranty/:uid', component:AddWarrantyComponent },
  { path: 'dealers', component:DealersComponent },
  { path: 'storehouses', component:StorehousesComponent },
  { path: 'search-product', component:SearchProductComponent },
  { path: 'add-sale', component:AddSaleComponent },
  { path: 'brands', component:BrandsComponent },
  { path: 'add-brand', component:AddBrandComponent },
  { path: 'edit-brand/:uid', component: EditBrandComponent},
  { path: 'edit-user/:uid', component: EditUserComponent},
  { path: 'edit-delivery/:uid', component: EditDeliveryComponent },
  { path: 'add-document', component:AddDocumentComponent },
  { path: 'edit-document/:uid', component: EditDocumentComponent },
  { path: 'edit-warranty/:uid', component: EditWarrantyComponent },
  { path: 'edit-sale/:uid', component: EditSaleComponent },
  { path: 'service', component: GeneralServiceComponent },
  { path: 'drone-service', component: DroneServiceComponent },
  { path: 'add-drone-service', component:AddDroneServiceComponent },
  { path: 'edit-drone-service/:uid', component: EditDroneServiceComponent },
  { path: 'profile', component:ProfileComponent },
  { path: 'other', component:OtherComponent },
  { path: 'help', component:HelpComponent },
  { path: 'add-fuji-service', component:AddFujiServiceComponent },
  { path: 'edit-fuji-service/:uid', component: EditFujiServiceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
