<div class="print-layout" *ngIf="printing_modal.open" style="position:relative;top:unset;height:unset;z-index:unset;">
    <div class="page-print-title">
        <span style="margin-right:10px;" (click)="PrintProtocol()" class="right-btn">Разпечатване</span>
        <span style="margin-right:10px;" (click)="printing_modal.open = false; system.SetPrinting(false)" class="right-btn negative">Отказ</span>
    </div>
    <img src="../../assets/tuv-header.svg" />

    <h3>ПРИЕМО-ПРЕДАВАТЕЛЕН
    <br>ПРОТОКОЛ </h3>
    <span class="subtitle">За извършена работа </span>

    <div class="main-text">
        Днес, ………………., представителят на фирма {{ printing_modal.obj.client_name }}, <br> …………………………………………………………………………………………………... приех от представителят на ТЕМПЕКС ЕООД ………………………………………………………………………………………………………………., доставени и монтирани на aдрес {{ printing_modal.obj.client_address }}, {{ printing_modal.obj.town }}, следните климатични системи: 
    </div>

    <table cellpadding="0" cellspacing="0">
        <thead>
            <tr>
                <th>№</th>
                <th>Марка</th>
                <th>Модел - Вътрешно <br> Сериен - Вътрешно</th>
                <th>Модел - Външно <br> Сериен - Външно</th>
                <th>Общо тръби (л.м)</th>
                <th>Зареден фреон(кг)</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ac of printing_modal.obj.ac; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ ac.brand }}</td>
                <td>{{ ac.model_indoor }} <br> {{ ac.serial_indoor }}</td>
                <td>{{ ac.model_outdoor }} <br> {{ ac.serial_outdoor }}</td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>

    <div class="main-text" style="margin-top:120px;">
        Направа на отвори в бетон:……………………………………………………………………………………………………<br>
        Електрозахранване от ел. табло:……………………………………………………………………………………………<br>
        Декоративен канал:………………………………………………………………………………………………………………<br>
        Кондензен тръбопровод: ………………………………………………………………………………………………………<br>
        <br>
        <br>
        <br>
        Доп.СМР:………………………………………………………………………………………………………………………………<br>
        ……………………………………………………………………………………………………………………………………………<br>
        ……………………………………………………………………………………………………………………………………………<br>
        
    </div>

    <div class="signature">
        <div class="sign">ПОЛУЧИЛ....................</div>
        <div class="sign" style="text-align:right;">ПРЕДАЛ.....................</div>
    </div>
</div>
<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-warranty'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете гаранционна карта "{{modal.obj.warranty_id}}" от системата ?</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteWarranty(modal.obj.uid, modal.obj.warranty_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>

    <div class="alert" *ngIf="modal.cntx == 'loading-warranties'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на гаранционни карти...</span>
    </div>

</div>

<div class="content" *ngIf="!system.ReturnPrinting()">
    <span class="page-title">
        <span class="name">Гаранциони карти</span>
        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_type == 'warranty_id'">Номер:</strong>
            <strong class="type" *ngIf="search_type == 'client_name'">Име на клиент:</strong>
            <strong class="type" *ngIf="search_type == 'service_name'">Издател:</strong>
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_type">
                <option value="warranty_id">Номер на карта</option>
                <option value="client_name">Име на клиент</option>
                <option value="service_name">Издател</option>
            </select>
            <input type="text" (keydown.enter)="searchWarranties()" style="height:30px" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchWarranties()"><i class="icon search-white"></i></div>
        </div> 
        <span routerLink="/add-warranty/-1" class="right-btn">Нова гаранционна карта</span>
        <div class="checkbox-right" (click)="switchOnlyMine()" *ngIf="system.returnIsAdmin() || system.returnRole() == 3">
            <span>Покажи само моите карти</span>
            <div class="checkable" [ngClass]="{ 'active':only_mine == '1' }"></div>
        </div>
        <div class="checkbox-right"  *ngIf="system.returnIsAdmin() || system.returnRole() == 3" (click)="switchInstallationDate()">
            <span>Показване на дата на монтаж при печат</span>
            <div class="checkable" [ngClass]="{ 'active':installationDateOn }"></div>
        </div>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-2 col-lg-1 text-center">#</div>
            <div class="col-xl-2 col-lg-1 text-center">Издадено от</div>
            <div class="col-xl-2 col-lg-1 text-center">Издадено на</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на издаване</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на монтаж</div>
            <div class="col-xl-2 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="warranties.length == 0 && searched">
            <div class="col-xl-12 col-lg-12 text-center">Няма резултати за търсенете</div>
        </div>
        <div class="table-wrapper-row" *ngIf="warranties.length == 0 && !searched">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени гаранционни карти</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let warranty of warranties; let i = index">
            <!-- <div class="extendable" id="extend-btn1"><i class="icon arrow-down"></i></div> -->
            <div class="col-xl-2 col-lg-1 text-center"> {{ warranty.warranty_id }}</div>
            <div class="col-xl-2 col-lg-1 text-center text-overflow">{{ warranty.service_name }}</div>
            <div class="col-xl-2 col-lg-1 text-center text-overflow">{{ warranty.client_name }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ warranty.reformed_purchase_date }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ warranty.reformed_installation_date }} 
                <span class="text-hintable" *ngIf="warranty.duration < 1">(Днес)</span>
                <span class="text-hintable" *ngIf="warranty.duration > 1 && warranty.duration < 2">(Преди {{ returnDays(warranty.duration) }} ден)</span>
                <span class="text-hintable" *ngIf="warranty.duration >= 2 && warranty.duration <= 30">(Преди {{ returnDays(warranty.duration) }} дни)</span>
                <span class="text-hintable" *ngIf="warranty.duration > 30 && warranty.duration <= 60">(Преди {{ returnMonths(warranty.duration) }} месец)</span>
                <span class="text-hintable" *ngIf="warranty.duration > 60 && warranty.duration <= 360">(Преди {{ returnMonths(warranty.duration) }} месеца)</span>
                <span class="text-hintable" *ngIf="warranty.duration > 360 && warranty.duration <= 720">(Преди {{ returnYears(warranty.duration) }} година)</span>
                <span class="text-hintable" *ngIf="warranty.duration > 720">(Преди {{ returnYears(warranty.duration) }} години)</span>
            </div>
            <div class="col-xl-2 col-lg-1 text-center">
                <div class="action-btn" *ngIf="warranty.editable" routerLink="/edit-warranty/{{ warranty.uid }}"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                <div class="action-btn" (click)="prePrintWarranty(warranty)"><i class="icon view"></i> <span class="tooltip">Преглед</span></div>
                <div class="action-btn" (click)="printWarranty(warranty)"><i class="icon print"></i> <span class="tooltip">Принтиране</span></div>
                <div class="action-btn" (click)="PrintWarrantyProtocol(warranty)" *ngIf="system.returnIsAdmin() || system.returnRole() == 1 || system.returnRole() == 3"><i class="icon view-protocol"></i> <span class="tooltip">Изкарване на протокол</span></div>
                <div class="action-btn negative" *ngIf="warranty.editable" (click)="promptDelete(warranty.uid, warranty.warranty_id)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
            </div>
            <div class="expandable" id="expandable1">
                <div class="table-wrapper-row table-wrapper-small-header ">
                    <div class="col-xl-1 col-lg-1 text-center" style="color: #fbfbfb;">-</div>
                    <div class="col-xl-3 col-lg-1 text-center">Марка</div>
                    <div class="col-xl-2 col-lg-1 text-center">Модел (Вътрешно тяло)</div>
                    <div class="col-xl-2 col-lg-1 text-center">Модел (Външно тяло)</div>
                    <div class="col-xl-2 col-lg-1 text-center">Сериен №(Вътрешно тяло)</div>
                    <div class="col-xl-2 col-lg-1 text-center">Сериен №(Външно тяло)</div>
                </div>
                <div class="table-wrapper-row smaller">
                    <div class="col-xl-1 col-lg-1 text-center line-elem"><div class="elem-holder"></div></div>
                    <div class="col-xl-3 col-lg-1 text-center">Fuji Electric</div>
                    <div class="col-xl-2 col-lg-1 text-center">RSG12LMCA</div>
                    <div class="col-xl-2 col-lg-1 text-center">ROG12LMCA.</div>
                    <div class="col-xl-2 col-lg-1 text-center">E002057</div>
                    <div class="col-xl-2 col-lg-1 text-center">E001901</div>
                </div>
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>

            <!-- <span (click)="switchPage(i + 1)" *ngFor="let page of total_pages_array;let i= index" [ngClass]="{ 'active':i + 1 == currentPage }">{{ i + 1 }}</span> -->
        </div>
    </div>
</div>