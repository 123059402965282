<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>
    <div class="alert" *ngIf="modal.cntx == 'remove-protocol'">
        <span class="alert-text">Сигурни ли сте, че искате да премахнете този протокол от системата</span>
        <div class="btn-holder">
            <div class="btn positive" (click)="deleteProtocol(modal.obj.protocol_id)">Да</div>
            <div class="btn negative" (click)="closeModal()">Не</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'session-timeout'">
        <span class="alert-text">Вашията сесия е изтекла, моля влезте отново</span>
        <div class="btn-holder">
            <div class="btn positive" style="width:100%;">Приемам</div>
        </div>
    </div>
    <div class="alert" *ngIf="modal.cntx == 'loading-fuji-services'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Зареждане на протоколи...</span>
    </div>
</div>

<div class="content">
    <span class="page-title">
        <span class="name">Сервиз - "FUJI"</span>
        <div class="label-searched" (click)="clearSearched()" *ngIf="searched">
            <strong class="type" *ngIf="search_by == 'client_name'">Име на клиент:</strong>
            <!-- <strong class="type" *ngIf="search_by == 'client_phone'">Телефон на клиент:</strong>
            <strong class="type" *ngIf="search_by == 'serial_number'">Сериен номер:</strong>
            <strong class="type" *ngIf="search_by == 'service_num'">Номер на протокол:</strong> -->
            <span>{{ search }}</span>
            <i class="icon delete-label"></i>
        </div>
        <div class="search-title-box" *ngIf="!searched">
            <select [(ngModel)]="search_by">
                <option value="client_name">Име на клиент</option>
                <!-- <option value="client_phone">Телефон на клиент</option>
                <option value="service_num">Номер на протокол</option>
                <option value="serial_number">Сериен номер</option>
                <option value="serial_number">Модел</option> -->
            </select>
            <!-- (keydown.enter)="searchSales()" -->
            <!-- <input type="text"  (keydown.enter)="searchProtocolDrones()" style="height:30px;width:240px;" [(ngModel)]="search" />
            <div class="round-btn" (click)="searchProtocolDrones()"><i class="icon search-white"></i></div> -->
        </div> 
        <span routerLink="/add-fuji-service" class="right-btn">Нов протокол</span>
    </span>
    <div class="table-wrapper">
        <div class="table-wrapper-header-row">
            <div class="col-xl-1 col-lg-1 text-center text-overflow">Протокол №</div>
            <div class="col-xl-2 col-lg-2 text-center">Търговец</div>
            <div class="col-xl-2 col-lg-2 text-center">Клиент</div>
            <div class="col-xl-2 col-lg-1 text-center">Състояние</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на входиране</div>
            <div class="col-xl-2 col-lg-1 text-center">Дата на връщане</div>
            <div class="col-xl-1 col-lg-1 text-center">Действия</div>
        </div>
        <div class="table-wrapper-row" *ngIf="protocols.length == 0">
            <div class="col-xl-12 col-lg-12 text-center">Няма добавени протоколи</div>
        </div>
        <div class="table-wrapper-row selectable" *ngFor="let protocol of protocols; let i = index">
            <div class="col-xl-1 col-lg-1 text-center text-overflow">{{ protocol.service_num }}</div>
            <div class="col-xl-2 col-lg-2 text-center">
                <span *ngIf="protocol.seller_id == -1">ТЕМПЕКС ЕООД</span>
                <span *ngIf="protocol.seller_id != -1">Дилър</span>
            </div>
            <div class="col-xl-2 col-lg-2 text-center">{{ protocol.client_name }}</div>
            <div class="col-xl-2 col-lg-1 text-center">
                <div class="status-badge waiting-status" *ngIf="protocol.status == 0">Чака обработка</div>
                <div class="status-badge replaced-status" *ngIf="protocol.status == 1">Подменен продукт</div>
                <div class="status-badge denied-status" *ngIf="protocol.status == 2">Отказана гаранция</div>
            </div>
            <div class="col-xl-2 col-lg-1 text-center">{{ protocol.date_of_service }}</div>
            <div class="col-xl-2 col-lg-1 text-center">{{ protocol.date_of_return }}</div>
            <div class="col-xl-1 col-lg-1 text-center">
                <div class="action-btn" routerLink="/edit-fuji-service/{{ protocol.service_num }}"><i class="icon edit"></i><span class="tooltip">Промяна</span></div>
              <div class="action-btn" (click)="printFujiProtocol(protocol)"><i class="icon print"></i> <span class="tooltip">Принтиране</span></div>
              <div class="action-btn negative" *ngIf="system.returnIsAdmin() || system.returnRole() == 3" (click)="promptDelete(protocol.uid)"><i class="icon remove-white"></i> <span class="tooltip">Изтриване</span></div>
                <!-- <div class="action-btn" (click)="printDroneProtocol(protocol)"><i class="icon print"></i> <span class="tooltip">Принтиране</span></div>
                <div class="action-btn" *ngIf="system.returnIsAdmin() || system.returnRole() == 4" routerLink="/edit-drone-service/{{ protocol.uid }}"><i class="icon edit"></i> <span class="tooltip">Промяна</span></div>
                 -->
            </div>
        </div>
        <div class="pagination-row">
            <span *ngIf="currentPage > 2" (click)="switchPage(1)">1</span>
            <span class="non-hover" *ngIf="currentPage > 3">...</span>
            <span *ngIf="currentPage > 1" (click)="switchPage(currentPage - 1)">{{ currentPage - 1 }}</span>
            <span class="active">{{ currentPage}}</span>
            <span *ngIf="currentPage + 1 < total_pages" (click)="switchPage(currentPage + 1)">{{ currentPage + 1 }}</span>
            <span *ngIf="currentPage + 2 < total_pages" (click)="switchPage(currentPage + 2)">{{ currentPage + 2 }}</span>
            <span class="non-hover" *ngIf="currentPage + 1 < total_pages - 2">...</span>
            <span *ngIf="currentPage <= total_pages - 1" (click)="switchPage(total_pages)">{{ total_pages }}</span>
        </div>
    </div>
</div>