<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
  <div class="backdrop"></div>
  <div class="alert" *ngIf="modal.cntx == 'logging'">
      <div class="loader">
          <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
          <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
      </div>
      <span class="loader-text">Проверка на потребител...</span>
  </div>
  <div class="alert" *ngIf="modal.cntx == 'logging-in'">
    <div class="loader">
        <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
    </div>
    <span class="loader-text">Влизане в система...</span>
  </div>
  <div class="alert" *ngIf="modal.cntx == 'logging-out'">
    <div class="loader">
        <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
    </div>
    <span class="loader-text">Излизане от система...</span>
  </div>
  <div class="profile" *ngIf="modal.cntx == 'profile'">
    <div class="profile-close" (click)="closeModal()"></div>
    <div class="avatar">{{ system.returnName().substring(0, 1) }}</div>
    <div class="role">{{ system.returnTitle() }}</div>
    <div class="name">{{ system.returnName() }}</div>
    <div class="action-list">
      <!-- <div class="action-list-item" (click)="closeModal()">Назад</div> -->
      <div class="action-list-item" routerLink="/profile" (click)="closeModal()">Моят профил</div>
      <div class="action-list-item" (click)="Logout()">Изход</div>
    </div>
  </div>
</div>

<div style="float:left;width:100%;height:100%;" *ngIf="system.returnLogged()">
  <div class="header" *ngIf="!system.ReturnPrinting()">
    <div class="main-navigation" [ngClass]="{ 'black':system.returnName() == 'Илияна Христова' || system.returnName() == 'Илия Щерев' }">
        <ul class="menu">
            <li routerLink="/deliveries" *ngIf="system.returnIsAdmin() || system.returnRole() < 4" [ngClass]="{ 'active':returnRoute() == '/deliveries' || returnRoute() == '/sales' || returnRoute() == '/search-product' || returnRoute() == '/deliveries-dealers' }">Склад</li>
            <li routerLink="/warranties" routerLinkActive="active" *ngIf="system.returnIsAdmin() || system.returnRole() < 4">Гаранциони карти</li>
            <li routerLink="/documentation" routerLinkActive="active" *ngIf="system.returnIsAdmin() || system.returnRole() < 4">Документация</li>
            <li routerLink="/drone-service" routerLinkActive="active" *ngIf="system.returnIsAdmin() || system.returnRole() == 4 || system.returnRole() == 5 || system.returnName() == 'Тестов Дилър'">Сервиз</li>
            <!-- <li routerLink="/service" routerLinkActive="active" *ngIf="system.returnName() == 'Пламен Петров'">Сервиз</li> -->
            <li routerLink="/users" [ngClass]="{ 'active':returnRoute() == '/users' || returnRoute() == '/dealers' || returnRoute() == '/storehouses' || returnRoute() == '/brands' }" *ngIf="system.returnIsAdmin()">Настройки</li>
            <!-- <li routerLink="/brands" [ngClass]="{ 'active':returnRoute() == '/brands' }" *ngIf="system.returnIsAdmin()">Марки</li> -->
            <!-- <li routerLink="/profile" [ngClass]="{ 'active':returnRoute() == '/profile' }">Моят профил</li> -->
            <li routerLink="/other" *ngIf="system.returnName() == 'Илияна Христова' || system.returnName() == 'Илия Щерев' || system.returnName() == 'Пламен Петров' || system.returnName() == 'Атанас Симонов'" [ngClass]="{ 'active':returnRoute() == '/other' }">Други</li>
            <li routerLink="/help" [ngClass]="{ 'active':returnRoute() == '/help' }">Помощ</li>
        </ul>
  
        <div class="user" (click)="openModal('profile', {})">
            <div class="avatar">{{ system.returnName().substring(0, 1) }} <i class="icon admin-icon" *ngIf="system.returnIsAdmin()"></i></div>
            <div class="user-info">
              <div class="username">{{ system.returnName() }}</div>
              <div class="role">{{ system.returnTitle() }}</div>
            </div>
            <i class="icon arrow-down"></i>
            <!-- <ul class="dropdown" >
                <li (click)="system.Logout()">Изход</li>
            </ul> -->
        </div>
    </div>
    <div class="sub-navigation" *ngIf="!returnRouteExists('/add-delivery') && !returnRouteExists('/edit-delivery') &&  !returnRouteExists('/other')  && !returnRouteExists('/add-sale') && !returnRouteExists('/edit-sale') && !returnRouteExists('/add-warranty') && !returnRouteExists('/profile') && !returnRouteExists('/help') && !returnRouteExists('/edit-warranty') && !returnRouteExists('/add-drone-service') && !returnRouteExists('/add-fuji-service') && !returnRouteExists('/edit-fuji-service') && !system.ReturnPrinting()">
          <ul class="menu">
            <!-- STORAGE TABS -->
             <!-- *ngIf="system.returnIsAdmin() || system.returnRole() < 2  || system.returnRole() == 3" -->
            <li class="hoverable" routerLink="/deliveries" routerLinkActive="active" *ngIf="ReturnIsInDeliveiresAndIsAllowed()"><i class="icon delivery"></i> <span>Доставки</span></li>
            <li class="hoverable" routerLink="/deliveries-dealers" routerLinkActive="active" *ngIf="ReturnIsInDeliveiresAndDealerAndIsAllowed()"><i class="icon delivery"></i> <span>Доставки</span></li>
            <li class="hoverable" routerLink="/sales" routerLinkActive="active" *ngIf="ReturnIsInDeliveiresAndIsAllowed()"><i class="icon sale"></i> <span>Продажби</span></li>
            <li class="hoverable" routerLink="/search-product" routerLinkActive="active" *ngIf="ReturnIsInDeliveiresAndIsAllowed() "><i class="icon search"></i> <span>Движение на продукт</span></li>
        
            <li class="hoverable" routerLink="/warranties" routerLinkActive="active" *ngIf="returnRoute() == '/warranties'"><i class="icon list"></i> <span>Списък гаранциони карти</span></li>
            <li class="hoverable" routerLink="/documentation" routerLinkActive="active" *ngIf="returnRoute() == '/documentation' || returnRoute() == '/error-codes' && system.returnIsAdmin()"><i class="icon list"></i> <span>Списък документация</span></li>
            <li class="hoverable" routerLink="/error-codes" routerLinkActive="active" *ngIf="returnRoute() == '/documentation' && system.returnIsAdmin() || returnRoute() == '/error-codes' && system.returnIsAdmin()"><i class="icon list"></i> <span>Списък с грешки</span></li>
           
            <li class="hoverable" routerLink="/drone-service" routerLinkActive="active" *ngIf="returnRoute() == '/drone-service' || returnRoute() == '/service'"><i class="icon list"></i> <span>Сервиз - "Дронове"</span></li>
            <li class="hoverable" routerLink="/service" routerLinkActive="active" *ngIf="returnRoute() == '/drone-service' && system.returnIsAdmin() || returnRoute() == '/service' && system.returnIsAdmin() || returnRoute() == '/drone-service' && system.returnName() == 'Тестов Дилър' || returnRoute() == '/service' && system.returnName() == 'Тестов Дилър'"><i class="icon list"></i> <span>Сервиз - "FUJI"</span></li>

            <!-- USERS TABS  -->
            <li class="hoverable" routerLink="/users" routerLinkActive="active" *ngIf="returnRoute() == '/users'  || returnRoute() == '/storehouses'  || returnRoute() == '/dealers' || returnRoute() == '/brands'"><i class="icon users"></i> <span>Списък потребители</span></li>
            <li class="hoverable" routerLink="/dealers" routerLinkActive="active" *ngIf="returnRoute() == '/users' || returnRoute() == '/storehouses'  || returnRoute() == '/dealers' || returnRoute() == '/brands'"><i class="icon dealer"></i> <span>Списък дилъри</span></li>
            <li class="hoverable" routerLink="/storehouses" routerLinkActive="active" *ngIf="returnRoute() == '/users' || returnRoute() == '/storehouses'  || returnRoute() == '/dealers' || returnRoute() == '/brands'"><i class="icon storehouse"></i> <span>Списък складове</span></li>
            <li class="hoverable" routerLink="/brands" routerLinkActive="active" *ngIf="returnRoute() == '/users' || returnRoute() == '/storehouses'  || returnRoute() == '/dealers' || returnRoute() == '/brands'"><i class="icon storehouse"></i> <span>Марки</span></li>
          </ul>
    </div>
  </div>
  <router-outlet></router-outlet>
  <footer *ngIf="!system.ReturnPrinting()">Системата е изработена от <a href="https://axion.solutions/?tempex-warranty">Аксион Солюшънс</a></footer>
</div>

<div class="login-screen" *ngIf="!system.returnLogged()">
  <!-- <div class="dark-overlay"> -->
    <div class="login-modal">
      <span class="login-title" style="    position: absolute;
      top: -105px;
      color: white;
      width: 310px;
      line-height: unset;
      left: 90px;
      font-weight: bold;
      font-size: 40px;
      text-align: center;">ГАРАНЦИОННА СИСТЕМА</span>
      <span class="login-title" style="color:#02477e;">Вход</span>
      <span class="error" *ngIf="error">Грешен потребител или парола</span>
      <div class="login-row">
        <span class="label">Имейл</span>
        <input type="text" [(ngModel)]="loginForm.email" (keyup.enter)="system.Login(loginForm.email, loginForm.password, this)" (change)="error = false" placeholder="" />
      </div>
      <div class="login-row">
        <span class="label">Парола</span>
        <input type="password" [(ngModel)]="loginForm.password" (keyup.enter)="system.Login(loginForm.email, loginForm.password, this)" (change)="error = false" placeholder="" />
      </div>
      <div class="login-btn" (click)="system.Login(loginForm.email, loginForm.password, this)">Влизане</div>
      <img src="../assets/tempex-white-logo.svg" style="    width: 300px;
      position: absolute;
      bottom: -100px;
      left: 95px;" />
    </div>
  <!-- </div> -->
</div>
