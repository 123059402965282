import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { LocalSystemService } from '../local-system.service';

@Component({
  selector: 'app-warranties',
  templateUrl: './warranties.component.html',
  styleUrls: ['./warranties.component.scss']
})
export class WarrantiesComponent implements OnInit {
  warranties:any = [];
  currentPage:any = 1;
  total_pages = 0;
  total_pages_array = [];

  search_type:any = "warranty_id";
  search:any = "";
  searched:any = false;

  printing_modal:any = { open:false, 'obj':'' }

  modal:any = { open:false, cntx:"", obj:{} }

  only_mine:any = "0";
  installationDateOn = true;

  constructor(private http:HttpClient, public system:LocalSystemService, public ngZone:NgZone) {
    this.getWarranties();
  }

  ngOnInit(): void {
    this.http.get("api/service.php?action=get_installation_date").subscribe((data) => {
      if(data['logged']) {
        let boolValue = (data['installationDate'] === "true"); 
        this.installationDateOn = boolValue;
        this.ngZone.run(()=>{});
      } else {
        this.system.Logout();
      }
    });
  }

  getWarranties() {
    this.openModal('loading-warranties', {})
    this.http.get("api/service.php?action=get_warranties&only_mine="+ this.only_mine +"&page=" + this.currentPage).subscribe((data) => {
      if(data['logged']) {
        this.warranties = data['warranties'];
        this.total_pages = data['total_pages'];
        this.total_pages_array = [];
        for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
        this.closeModal();
      } else {
        this.system.Logout();
      }
    });
  }

  searchWarranties() {
    this.currentPage = 1;
    this.searched = true;
    this.http.get("api/service.php?action=get_warranties&only_mine="+ this.only_mine +"&page=" + this.currentPage + "&search_type=" + this.search_type + "&search=" + this.search).subscribe((data) => {
      this.warranties = data['warranties'];
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
    });
  }

  clearSearched() {
    this.currentPage = 1;
    this.search = "";
    this.getWarranties();
    this.searched = false;
  }

  switchPage(page) {
    this.currentPage = page;
    if(!this.searched) { this.getWarranties(); }
    else { this.switchPageSearch(); }
  }

  PrintWarrantyProtocol(warranty) {
    let ac = [];
    let split_indoor_serial = warranty.serial_number_indoor.split(";");
    let split_outdoor_serial = warranty.serial_number_outdoor.split(";");
    let split_model_indoor = warranty.model_indoor.split(";");
    let split_model_outdoor = warranty.model_outdoor.split(";");
    let split_brand = warranty.brand.split(";");
    let new_warranty_obj = JSON.parse(JSON.stringify(warranty));
    for(var i = 0; i < split_indoor_serial.length; i++) {
      let obj_ac = { 'brand' :split_brand[i], 'serial_indoor': split_indoor_serial[i], 'serial_outdoor':split_outdoor_serial[i], 'model_indoor':split_model_indoor[i], 'model_outdoor':split_model_outdoor[i] };
      ac.push(obj_ac);
    }
    new_warranty_obj['ac'] = ac;
    this.printing_modal = { open:true, 'obj':new_warranty_obj };
    this.system.SetPrinting(true);
  }

  PrintProtocol() {
    window.print();
  }

  switchPageSearch() {
    this.openModal('loading-warranties', {})
    this.http.get("api/service.php?action=get_warranties&only_mine="+ this.only_mine +"&page=" + this.currentPage + "&search_type=" + this.search_type + "&search=" + this.search).subscribe((data) => {
      this.warranties = data['warranties'];
      this.total_pages = data['total_pages'];
      this.total_pages_array = [];
      for(let i = 0; i < this.total_pages; i++) { this.total_pages_array.push(0); }
      this.closeModal();
    });
  }

  switchOnlyMine() { 
    if(this.only_mine == "0") { this.only_mine = "1"; } else if(this.only_mine == '1') { this.only_mine = "0"; } 
    this.getWarranties();
  }

  switchInstallationDate() {
    this.installationDateOn = !this.installationDateOn;
    this.http.get("api/service.php?action=set_installation_date_on&installation_date=" + this.installationDateOn).subscribe((data) => {
    });
  }

  promptDelete(uid, warranty_id) { this.openModal('remove-warranty', { 'uid':uid, 'warranty_id':warranty_id }); }
  deleteWarranty(uid, warranty_id) {
    this.http.get("api/service.php?action=remove_warranty&uid=" + uid + "&warranty_id=" + warranty_id).subscribe((data) => {
      this.closeModal();
      this.getWarranties();
    });
  }

  
  printWarranty(warranty) {
    window.location.href = 'http://warranty.tempex.bg/print.php?warranty_id=' + warranty.warranty_id;
  }

  prePrintWarranty(warranty) {
    window.location.href = 'http://warranty.tempex.bg/preprint.php?warranty_id=' + warranty.warranty_id;
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj}; } 
  closeModal() { this.modal = { open:false, cntx:"", obj:{} }; }


  returnDays (day){
    return Math.round(day);
  }

  returnMonths (day) {
    return Math.floor(day / 30);
  }

  returnYears(day)  {
    if(Math.floor((day / 30) / 360) == 0) {
      return 1;
    } else {
      return  Math.floor((day / 30) / 360)
    }
  }
}
