<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
    <div class="backdrop"></div>

    <div class="alert" *ngIf="modal.cntx == 'adding-protocol'">
        <div class="loader">
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
            <div class="obj"></div><div class="obj"></div><div class="obj"></div><div class="obj"></div>
        </div>
        <span class="loader-text">Промяна на протокол...</span>
    </div>
</div>

<div class="content no-subheader">
    <span class="page-title">
        Промяна на протокол 
        <span class="right-btn" (click)="EditProtocol()">Запис</span>
        <span routerLink="/service" class="right-btn negative">Отказ</span>
    </span>

    <div class="tab-holder">
        <div class="tab-headers">
            <div class="tab-header" (click)="switchTab('general')" [ngClass]="{ 'active':tab == 'general' }">Основна информация</div>
            <div class="tab-header" *ngIf="system.returnRole() != 2 || system.returnIsAdmin() && system.returnRole() != 4 || system.returnIsAdmin()" (click)="switchTab('conclusion')" [ngClass]="{ 'active':tab == 'conclusion' }">Заключение от сервиз</div>
        </div>
        <div class="tab-content">
            <div class="tab" *ngIf="tab == 'general'">
                <div class="category-title">Основна информация</div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <span class="label">Номер на протокол</span>
                    <input type="text" disabled placeholder="Въведете наименование"  [(ngModel)]="editProtocol.service_num" />
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <div id="delivery-date-picker" style="height:310px;bottom:-310px;" class="date-picker bot" *ngIf="editProtocol.date_of_sale != ''" [ngClass]="{ 'active':sale_date_picker }" (clickOutside)="sale_date_picker = false">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_sale_date)) }} {{ returnYear(current_sale_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_sale_date) && dt.month == returnMonth(editProtocol.date_of_sale) - 1 }" (click)="selectDate(dt, 'date_of_sale')">{{ dt.date }}</span>
                            </div>
                            <div class="no-date-btn" (click)="sale_date_picker = false;editProtocol.date_of_sale = ''; ">
                                Премахни дата
                            </div>
                        </div>
                    </div>
                    <span class="label">Дата на продажба</span>
                    <input type="text" placeholder="Въведете дата на покупка" (input)="ChangeDate('date_of_sale')" [(ngModel)]="editProtocol.formatted_date_of_sale" (click)="openPicker('date_of_sale')"  />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <!-- <div id="delivery-date-picker" class="date-picker bot" [ngClass]="{ 'active':date_sale_picker }" (clickOutside)="date_sale_picker = false">
                        <div class="datepicker-header">
                            <div class="datepicker-left-tool hoverable" (click)="prevMonth()"><i class='icon arrow-left'></i></div>
                            <span class="datepicker-title" id="date_delivery_title">{{ returnMonthName(returnMonth(current_incoming_date)) }} {{ returnYear(current_incoming_date) }}</span>
                            <div class="datepicker-right-tool hoverable" (click)="nextMonth()"><i class='icon arrow-right'></i></div>
                        </div>
                        <div class="datepicker-content">
                            <div class="datepicker-title-row">
                                <span>Пн</span>
                                <span>Вт</span>
                                <span>Ср</span>
                                <span>Чт</span>
                                <span>Пт</span>
                                <span>Сб</span>
                                <span>Нд</span>
                            </div>
                            <div class="datepicker-row" id="date_delivery_body">
                                <span *ngFor="let dt of current_sale_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(current_incoming_date) && dt.month == returnMonth(editProtocol.date_of_incoming) - 1 }" (click)="selectDate(dt, 'date_of_incoming')">{{ dt.date }}</span>
                            </div>
                        </div>
                    </div> -->
                    <span class="label">Дата на инсталация</span>
                    <input type="text" placeholder="Въведете дата на инсталация" (input)="ChangeDate('date_of_installation')" [(ngModel)]="editProtocol.formatted_date_of_installation" (click)="openPicker('date_of_installation')"  />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Гаранционна карта</span>
                    <input type="text" placeholder="Въведете номер на гар.карта"  [(ngModel)]="editProtocol.warranty_num" />
                    <div class="add-btn" (click)="FetchWarrantyCardInfo()" [ngClass]="{ 'disabled':editProtocol.warranty_num.length < 1 }"><i class="icon download-info"></i></div>
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <span class="label">Телефон на търговец</span>
                    <input type="text" placeholder="Въведете телефон на търговец"  [(ngModel)]="editProtocol.seller_phone" />
                </div>
                <div class="category-title">Информация за клиент</div>
                <div class="col-xl-4 col-lg-4 input-row">
                    <span class="label">Име на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.client_name" />
                </div>
                <div class="col-xl-4 col-lg-4 input-row">
                    <span class="label">Телефон на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.client_phone" />
                </div>
                <div class="col-xl-4 col-lg-4 input-row">
                    <span class="label">Адрес на клиент</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.client_address" />
                </div>
                <div class="category-title">Информация за продукт</div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Модел (Вътрешно тяло)</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.model_indoor" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Сериен номер (Вътрешно тяло)</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.serial_indoor" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Модел (Външно тяло)</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.model_outdoor" />
                </div>
                <div class="col-xl-3 col-lg-3 input-row">
                    <span class="label">Сериен номер  (Външно тяло)</span>
                    <input type="text" placeholder="Въведете наименование"  [(ngModel)]="editProtocol.serial_outdoor" />
                </div>
                <div class="category-title">Констатация от търговец <div class="add-defected-btn" (click)="AddNewDefectedPart()">Добавяне на констатация</div></div>
                <table class="config-table" border="1" cellpadding="0" cellspacing="0">
                    <thead>
                        <tr>
                            <th width="30%" style="text-align:left;padding-left:10px;">Дефектирала част</th>
                            <TH width="70%" style="text-align:left;padding-left:10px;">Причина</TH>
                        </tr>
                    </thead>
                    <tbody>
                         <tr *ngFor="let note of editProtocol.dealer_notes">
                            <td><input type="text" [(ngModel)]="note.part" /></td>
                            <td><input type="text" [(ngModel)]="note.description" /></td>
                         </tr>   
                    </tbody>
                </table>
                <!-- <div class="col-xl-12 col-lg-12 input-row">
                    <span class="label">Описание на дефекта / оплакване</span>
                    <textarea  [(ngModel)]="editProtocol.description"></textarea>
                </div> -->
            </div>
            <div class="tab"  *ngIf="tab == 'conclusion'">
                <div class="col-xl-12 col-lg-12 input-row">
                    <span class="label">Заключение от сервиз</span>
                    <textarea  [(ngModel)]="editProtocol.outcome"></textarea>
                </div>
                <div class="col-xl-2 col-lg-2 input-row">
                    <span class="label">Статус</span>
                    <select [(ngModel)]="editProtocol.status">
                        <option value="0">Чака обработка</option>
                        <option value="1">Подменен продукт</option>
                        <option value="2">Отказана гаранция</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>